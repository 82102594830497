<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="edit_XZBH.name" width="100%" height="80%" placement="bottom" :closable="true" :visible="visible" @close="onClose">

            <a-row style=" padding: 15px">
                <a-col :span="24" style=" padding-bottom: 15px">
                    <a-space><a-icon type="file-text"/><span class="font-semibold">添加细则标准：</span></a-space>
                </a-col>
                <a-col :span="24">
                    <a-form-model layout="inline"  v-bind="form_creat">
                        <a-form-model-item label="检验项目">
                            <a-input placeholder="请输入检验项目" v-model="form_creat.xm_name"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="检测方法">
                            <a-input placeholder="请输入检测方法" v-model="form_creat.ff_name"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="细则标准号">
                            <a-input placeholder="请输入细则标准号" v-model="form_creat.xz_serial" ></a-input>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-radio-group v-model="form_creat.status">
                                <a-radio :value="1">使用中</a-radio>
                                <a-radio :value="2">已停用</a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-item >
                            <a-button type="primary" @click="doClick()">保存</a-button>
                        </a-form-item>
                    </a-form-model>
                </a-col>
            </a-row>
            <a-row style="background: #f5f5f5; padding: 15px">
                <a-col :span="24">
                    <a-space style=" padding-bottom: 15px">
                        <a-icon type="search" /><span class="font-semibold">搜索：</span>
                    </a-space>
                </a-col>
                <a-col :span="24">
                    <a-form-model layout="inline"  v-bind="form_search">
                        <a-form-model-item >
                            <a-input placeholder="请输入关键词" v-model="form_search.xm_name" ></a-input>
                        </a-form-model-item>
                        <a-form-model-item >
                            <a-input placeholder="请输入检测方法" v-model="form_search.ff_name"></a-input>
                        </a-form-model-item>
                        <a-form-model-item >
                            <a-input placeholder="请输入细则标准号" v-model="form_search.xz_serial"></a-input>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-radio-group  v-model="form_search.status" @change="Get_check_bz_serial">
                                <a-radio :value="1">使用中</a-radio>
                                <a-radio :value="2">已停用</a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                            <a-space>
                                <a-button type="primary" @click="searchClick()">搜索</a-button>
                                <a-button  @click="Clear()">清空搜索</a-button>
                            </a-space>
                        </a-form-item>
                    </a-form-model>
                </a-col>
            </a-row>

            <table class="layui-table">
                <thead>
                <tr>
                    <th @click="yinziclickup(1)" style="cursor: pointer">
                        <span class="fl">检测项目</span>
                        <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -7px"/>
                                    </span>
                    </th>

                    <th @click="yinziclickup(2)" style="cursor: pointer">
                        <span class="fl">检测方法</span>
                        <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 2 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 2 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -7px"/>
                                    </span>
                    </th>

                    <th @click="yinziclickup(3)" style="cursor: pointer">
                        <span class="fl">细则标准号</span>
                        <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 3 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 3 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -7px"/>
                                    </span>
                    </th>

                    <th @click="yinziclickup(4)" style="cursor: pointer">
                        <span class="fl">状态</span>
                        <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 4 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 4 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -7px"/>
                                    </span>
                    </th>
                    <th>
                        <a-space><a-button type="primary" @click="allSave()">全部保存</a-button></a-space>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,key) in Get_check_bz_serial_list">
                    <td><a-input v-model="item.xm_name"></a-input></td>
                    <td><a-input v-model="item.ff_name"></a-input></td>
                    <td><a-input v-model="item.xz_serial"></a-input></td>
                    <td>
                        <a-radio-group v-model="item.status">
                            <a-radio :value="1">使用中</a-radio>
                            <a-radio :value="2">已停用</a-radio>
                        </a-radio-group>
                    </td>
                    <td>
                        <a-space>
                            <a-button type="primary" @click="EditClick(item)">保存</a-button>
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                <template slot="title">确认删除该项？</template>
                                <a-button type="danger" >删除</a-button>
                            </a-popconfirm>
                        </a-space>
                    </td>
                </tr>
                </tbody>
            </table>
            <a-pagination :current="0" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close','edit_XZBH' ],
        data: () => ({

            form_creat: {
                xm_name: '',
                ff_name: '',
                xz_serial: '',
                status: 1,
            },
            form_search: {
                check_bz_id:'',
                xm_name: '',
                ff_name: '',
                xz_serial: '',
                status: '',
                ordertype: '',
                sortrule:'',
            },
            Get_check_bz_serial_list:[],
            page: 1,
            count: 0,
            pagesize: 0,

            ordertype: 0,
            sortrule: 'asc',

            page_show:false,

        }),
        watch: {

        },
        mounted() {
            this.mountedDo()
            this.Get_check_bz_serial()//细则编号列表
        },


        methods: {
            mountedDo(){

            },
            // 搜索
            searchClick() {
                this.page = 1;
                this.Get_check_bz_serial()
            },
            Clear() {
                this.form_search = {
                    xm_name: '',
                    ff_name: '',
                    xz_serial: '',
                    status: '',
                }
                this.Get_check_bz_serial()
            },
            //删除
            delClick(item) {
                this.$sa0.post({
                    url: this.$api('Del_check_bz_serial'),
                    data: {
                        id: item.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_check_bz_serial()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            EditClick(item) {
                let data = {
                    xm_name:item.xm_name,
                    ff_name:item.ff_name,
                    status: item.status,
                    check_bz_serial_id:item.id,
                }
                this.$sa0.post({
                    url: this.$api('Edit_check_bz_serial'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            // this.close()
                            layer.msg('已保存')
                            // this.Get_check_bz_serial()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            allSave() {
                let content = []
                this.Get_check_bz_serial_list.forEach(function (item, index) {
                    let temp = {}
                    temp.check_bz_serial_id = item.id
                    temp.xm_name = item.xm_name
                    temp.xm_name = item.xm_name
                    temp.ff_name = item.ff_name
                    temp.xz_serial = item.xz_serial
                    temp.status = item.status
                    console.log(temp.xm_name)
                    content.push(temp)
                })
                this.$sa0.post({
                    url: this.$api('Create_check_bz_serial_all'),
                    data: {
                        content: content,
                        check_bz_id: this.edit_XZBH.id,
                    }

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('保存成功')
                            this.Get_check_bz_serial()
                            //this.getStype()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            doClick() {
                //console.log(this.edit_XZBH)
                let data = {
                    xm_name:this.form_creat.xm_name,
                    ff_name: this.form_creat.ff_name,
                    xz_serial: this.form_creat.xz_serial,
                    status: this.form_creat.status,
                    check_bz_id: this.edit_XZBH.id,
                }
                console.log(this.data),
                this.$sa0.post({
                    url: this.$api('Create_check_bz_serial'),
                    data: data,

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            // this.close()
                            layer.msg('已保存')
                            this.Get_check_bz_serial()
                            this.form_creat = {
                                xm_name:'',
                                ff_name:'',
                                xz_serial:'',
                                status:'',
                            }
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            // 分页
            onChange(page) {
                this.page = page,
                this.Get_check_bz_serial()
            },
            //排序
            yinziclickup(ordertype) {
                if (this.ordertype !== ordertype) {
                    this.ordertype = ordertype
                    this.sortrule = 'asc'
                } else {
                    if (this.sortrule === 'asc') {
                        this.sortrule = 'desc'
                    } else {
                        this.sortrule = 'asc'
                    }
                }
                this.$sa0.post({
                    url: this.$api('Get_check_bz_serial'),
                    data: {
                        page: this.page,
                        check_bz_id: this.edit_XZBH.id,
                        xm_name: this.form_search.xm_name,
                        ff_name: this.form_search.ff_name,
                        xz_serial: this.form_search.xz_serial,
                        status: this.form_search.status,
                        ordertype: this.ordertype,
                        sortrule: this.sortrule,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_check_bz_serial_list = response.data.result.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //细则编号列表
            Get_check_bz_serial() {
                console.log(this.edit_XZBH.id)
                this.$sa0.post({
                    url: this.$api('Get_check_bz_serial'),

                    data: {
                        page: this.page,
                        check_bz_id: this.edit_XZBH.id,
                        xm_name: this.form_search.xm_name,
                        ff_name: this.form_search.ff_name,
                        xz_serial: this.form_search.xz_serial,
                        status: this.form_search.status,
                        ordertype: this.form_search.ordertype,
                        sortrule: this.form_search.sortrule,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_check_bz_serial_list = response.data.result.list;
                            this.count = Number(response.data.result.count);//分页
                            this.pagesize = Number(response.data.result.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },



            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
        }
    }
</script>
<style scoped>
    .ml5{ margin-left: 5px}
    .ant-table-column-sorter{
        display: flex;
        flex-direction: column;
        margin-top: 1px;
        margin-left: 5px;
        cursor: pointer;
    }
    .jiantou{color: #999999}
    .classname{color: #1890ff;}
    .fl{float: left}
    .layui-table th{ text-align: left}
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .btn{
        position: relative;
        }

    .btn-up{
        display: inline-block;
        position: relative;
        margin-right: 10px;
        padding: 8px 15px;
        background: #09aaff;
        color: #fff;
        border: solid 1px #09aaff;
        border-radius: 3px
        }

    .btn-up input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0
        }

    .btn-new{
        display: inline-block;
        position: relative;
        padding: 5px 15px;
        /*border:solid 1px #09aaff;*/
        color: #09aaff;
        background: #fff;
        border-radius: 3px
        }

    .btn-up, .btn-up input, .btn-new{
        cursor: pointer;
        }

    .ml5{ margin-left: 10px}

</style>
