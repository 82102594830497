<template>
    <div>

        <a-row>
            <a-col :span="24" style="padding-bottom: 15px">
                <a-form-model layout="inline" :model="formSearch">
                    <a-form-model-item>
                        <a-input style="width: 260px" placeholder="请输入检测标准名称或编号" v-model="formSearch.name" @keyup="Get_check_bzpage"></a-input>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-radio-group v-model="formSearch.status" @change="Get_check_bzpage">
                            <a-radio :value="1">使用中</a-radio>
                            <a-radio :value="2">已停用</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-space>
                            <a-button type="danger" @click="Clear()" size="small">清空搜索</a-button>
                            <!--                            <a-button type="primary" v-if="item_info.type != 0 && item_info.det_item_ids!=''" size="small" @click="showDrawer({-->
                            <!--                                                  id:0,-->
                            <!--                                                  name:'',-->
                            <!--                                                  serial:'',-->
                            <!--                                                  file_url:'',-->
                            <!--                                                  file_name:'',-->
                            <!--                                                  item_id:'',-->
                            <!--                                                  remark:'',-->
                            <!--                                                  status:1,-->
                            <!--                                                  })">新增检测标准-->
                            <!--                            </a-button>-->
                        </a-space>
                    </a-form-model-item>
                </a-form-model>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <a-space style="margin-bottom: -12px; background: #ebf8fe; border: none" class="btner">
                    <div class="btnerlist" style="cursor: text;" v-if="item_info.type != 0 && item_info.det_item_ids!=''" @click="showDrawer({
                                                  id:0,
                                                  name:'',
                                                  serial:'',
                                                  file_url:'',
                                                  file_name:'',
                                                  item_id:'',
                                                  remark:'',
                                                  status:1,
                                                  })">
                        <i class="iconfont icon-fuzhi1" style="font-size: 15px; vertical-align: -1px"></i>新增检测标准
                    </div>
                    <div class="btnerlist" @click="showDrawerEdit()">
                        <i class="iconfont icon-yangbenluru_huaban1" style="font-size: 15px; vertical-align: -1px"></i>编辑
                    </div>
                    <div class="btnerlist" @click="showDrawerRecord()">
                        <i class="iconfont icon-yangpinmoban" style="font-size: 15px; vertical-align: -1px"></i>历史记录
                    </div>
                    <div class="btnerlist">
                        <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick()">
                            <template slot="title">确认删除该项？</template>
                            <i class="iconfont icon-yixiaohui" style="font-size: 15px; vertical-align: -1px"></i>删除
                        </a-popconfirm>
                    </div>
                </a-space>
            </a-col>
            <a-col :span="24">
                <table class="layui-table" lay-size="sm" style="margin-top: 11px">
                    <thead>
                    <tr>
                        <th style="width: 5%">
                            <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
                        </th>
                        <th @click="yinziclickup(1)" style="cursor: pointer; width: 300px">
                            <span>所属分类</span>
                            <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                            </div>
                        </th>

                        <th @click="yinziclickup(2)" style="cursor: pointer; width: 30%">
                            <span>检测标准名称</span>
                            <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[ordertype === 2 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[ordertype === 2 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                            </div>
                        </th>
                        <th width="100">创建人</th>
                        <th width="150">创建时间</th>
                        <th width="150">最后修改时间</th>
                        <th>状态</th>
                        <th>备注</th>
                        <!--                        <th>操作</th>-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,key) in Get_check_bz_list" :key="key" @click="clickrow(key)" :class="item.check? 'active':''">
                        <td>
                            <a-checkbox v-model="item.check"></a-checkbox>
                        </td>
                        <td>{{item.det_item_name}}</td>
                        <td>
                            <div @click="urlJump(item.file_url)"><a target='_blank'>{{item.name}}</a></div>
                            <div><a>{{item.serial}}</a></div>
                        </td>
                        <td>{{item.admin_name}}</td>
                        <td>{{item.create_time}}</td>
                        <td>
                            <span v-if="item.updatetime===null">{{item.create_time}}</span>
                            <span v-else> {{item.updatetime}}</span>
                        </td>
                        <td>{{item.status===1?'使用中':'已停用'}}</td>
                        <td style=" cursor: pointer; width: 200px">
                            <a-tooltip>
                                <template slot="title" v-if="item.remark !== ''">
                                    <span> {{item.remark}}</span>
                                </template>
                                <div style="width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                    <span v-if="item.remark !== ''">{{item.remark}}</span>
                                    <span v-if="item.remark === ''"></span>
                                </div>
                            </a-tooltip>
                        </td>
                        <!--                        <td style="text-align: left">-->
                        <!--                            <a-button type="link" @click="showDrawerEdit(item)">编辑</a-button>-->

                        <!--                            <a-button type="link" size="small" class="yellow" @click="showDrawerRecord(item)">历史记录-->
                        <!--                            </a-button>-->
                        <!--                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">-->
                        <!--                                <template slot="title">确认删除该项？</template>-->
                        <!--                                <a-button type="link" size="small" style="color:#ff0000">删除</a-button>-->
                        <!--                            </a-popconfirm>-->
                        <!--                        </td>-->
                    </tr>
                    </tbody>
                </table>
                <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
            </a-col>
        </a-row>
        <div v-if="edit_info">
            <Creatbiaozhun :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer" :visible="drawer_visible" :item_info="item_info"></Creatbiaozhun>
        </div>
        <div v-if="edit_infoEdit">
            <Editbiaozhun :edit_timeEdit="edit_timeEdit" :edit_infoEdit="edit_infoEdit" :close="onCloseDrawerEdit" :visible="drawer_visibleEdit" :item_info="item_info"></Editbiaozhun>
        </div>
        <div v-if="drawer_visibleXZBH">
            <Creatxizebianhao :edit_XZBH="edit_XZBH" :close="onCloseDrawerXZBH" :visible="drawer_visibleXZBH"></Creatxizebianhao>
        </div>
        <div v-if="edit_infoRecord">
            <jancebiaozhunRecord :edit_infoRecord="edit_infoRecord" :edit_timeRecord="edit_timeRecord" :close="onCloseDrawerRecord" :visible="drawer_visibleRecord"></jancebiaozhunRecord>
        </div>

    </div>
</template>
<script>
    import jancebiaozhunRecord from './jancebiaozhunRecord/jancebiaozhunRecord.vue';
    import Creatxizebianhao from './creatxizebianhao/creatxizebianhao.vue';
    import Editbiaozhun from './editbiaozhun/editbiaozhun.vue';
    import Creatbiaozhun from './creatbiaozhun/creatbiaozhun.vue';

    export default {
        name: "creatitem",
        components: {
            Creatbiaozhun, Editbiaozhun, Creatxizebianhao, jancebiaozhunRecord
        },
        props: ["reload", "item_info", "deep_info"],
        data() {
            return {
                all_active: false,
                formSearch: {
                    name: '',
                    status: '',
                },
                form_Search_XZ: {
                    name: '',
                    status: '',
                },

                edit_time: 0,
                edit_info: false,
                drawer_visible: false,
                edit_timeEdit: 0,
                edit_infoEdit: false,
                drawer_visibleEdit: false,
                edit_time_XZBH: 0,
                edit_XZBH: false,
                drawer_visibleXZBH: false,
                edit_timeRecord: 0,
                edit_infoRecord: false,
                drawer_visibleRecord: false,

                Get_check_bz_list: [],
                page: 1,
                count: 0,
                pagesize: 0,
                ordertype: 0,
                sortrule: 'asc',
                page_show: false,
                Get_check_bz_groupby_list: [],
                ipage: 1,
                icount: 0,
                ipagesize: 0,
                all_check: false,
            }
        },
        watch: {
            formSearch: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },

            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },

            itemActiveChange() {
                let do_ = true
                this.Get_check_bz_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Get_check_bz_list.map((item, key) => {
                    this.$set(this.Get_check_bz_list[key], 'check', this.all_check)
                })
            },
        },
        mounted() {
            this.mountedDo()
            console.log("=====================================================")
            console.log(666666, this.item_info)
            console.log(JSON.stringify(this.item_info))
        },
        methods: {
            itemActiveChange() {
                let do_ = true
                this.Get_check_bz_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Get_check_bz_list.map((item, key) => {
                    this.$set(this.Get_check_bz_list[key], 'check', this.all_check)
                })
            },

            urlJump(url) {
                if (url === "") {
                    layer.msg("还没有上传执行标准文档，请上传后下载")
                } else
                    window.open(url)
            },
            mountedDo() {
                this.Get_check_bz()// w检测标准文档列表
                this.Get_check_bz_groupby()
            },
            callback(key) {
                console.log(key);
            },


            // 检测细则搜索
            searchClick_XZ() {
                this.page = 1;
                this.Get_check_bz_groupby()
            },
            Clear_XZ() {
                this.form_Search_XZ = {
                    name: '',
                    status: '',
                }
                this.Get_check_bz_groupby()
            },
            // 分页
            ionChange(ipage) {
                this.ipage = ipage;
                this.Get_check_bz_groupby()
            },


            // w细则列表
            Get_check_bz_groupby() {
                this.$sa0.post({
                    url: this.$api('Get_check_bz_groupby'),
                    data: {
                        page: this.ipage,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_check_bz_groupby_list = response.data.result.list;
                            this.icount = Number(response.data.result.count);//分页
                            this.ipagesize = Number(response.data.result.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            jump(url) {
                if (url === '') {
                    layer.msg('您还未上传文件')
                } else {
                    window.open(url)
                }

            },
            //删除
            delClick(item) {

                // let id = 0
                // let count = 0
                // this.Get_check_bz_list.map((item, key) => {
                //     if (item.check) {
                //         id = item.id
                //         count++
                //     }
                // })
                // if (!id) return layer.msg('请选择检测标准')
                // if (count !== 1) return layer.msg('只能选择一个检测标准删除')


                let check_ids = []
                this.Get_check_bz_list.map(item => {
                    if (item.check) {
                        check_ids.push(Number(item.id))
                    }
                })
                if (check_ids.length < 1) {
                    layer.msg('请勾选需要操作的检测标准')
                    return
                }
                this.$sa0.post({
                    url: this.$api('Del_check_bz'),
                    data: {
                        id: check_ids.join(",")
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_check_bz()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //排序
            yinziclickup(ordertype) {
                if (this.ordertype !== ordertype) {
                    this.ordertype = ordertype
                    this.sortrule = 'asc'
                } else {
                    if (this.sortrule === 'asc') {
                        this.sortrule = 'desc'
                    } else {
                        this.sortrule = 'asc'
                    }
                }
                this.$sa0.post({
                    url: this.$api('Get_check_bz'),
                    data: {
                        page: this.page,
                        name: this.formSearch.name,
                        ordertype: this.ordertype,
                        sortrule: this.sortrule,
                        item_id: this.item_info.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_check_bz_list = response.data.result.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 搜索
            searchClick() {
                this.page = 1;
                this.Get_check_bz();
            },
            Clear() {
                this.formSearch = {
                    name: '',
                    status: '',
                }
                this.Get_check_bz();
            },
            // 分页
            onChange(page) {
                this.page = page;
                this.Get_check_bz();
            },
            clickrow(key) {
                this.$set(this.Get_check_bz_list[key], 'check', !this.Get_check_bz_list[key].check)
                this.itemActiveChange()
            },


            Get_check_bzpage() {
                this.page = 1
                this.Get_check_bz()
            },

            // w检测标准文档列表
            Get_check_bz() {
                this.$sa0.post({
                    url: this.$api('Get_check_bz'),
                    data: {
                        name: this.formSearch.name,
                        status: this.formSearch.status,
                        page: this.page,
                        ordertype: this.formSearch.ordertype,
                        sortrule: this.formSearch.sortrule,
                        item_id: this.item_info.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // this.Get_check_bz_list = response.data.result.list;
                            this.Get_check_bz_list = response.data.result.list.map((item) => {
                                item.check = false
                                return item;
                            });
                            this.count = Number(response.data.result.count);//分页
                            this.pagesize = Number(response.data.result.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 打开抽屉时的 历史记录
            showDrawerRecord() {
                let id = 0
                let count = 0
                let info = []
                this.Get_check_bz_list.map((item, key) => {
                    if (item.check) {
                        id = item.id
                        count++
                        info = item
                    }
                })
                if (!id) return layer.msg('请选择检测标准')
                if (count !== 1) return layer.msg('只能选择一个检测标准查看')
                this.edit_timeRecord = new Date() / 1;
                this.edit_infoRecord = info
                this.drawer_visibleRecord = true;
            },
            // 定义 关闭抽屉时的
            onCloseDrawerRecord() {
                this.Get_check_bz();
                this.drawer_visibleRecord = false;
            },
            // 定义 打开抽屉时的 新增细则编号
            showDrawerXZBH(item) {
                this.edit_time_XZBH = new Date() / 1;
                this.edit_XZBH = item
                this.drawer_visibleXZBH = true;
            },
            // 定义 关闭抽屉时的
            onCloseDrawerXZBH() {
                this.Get_check_bz();
                this.drawer_visibleXZBH = false;
            },
            // 定义 打开抽屉时的 编辑检测标准
            showDrawerEdit() {
                let id = 0
                let count = 0
                let info = []
                this.Get_check_bz_list.map((item, key) => {
                    if (item.check) {
                        id = item.id
                        count++
                        info = item
                    }
                })
                if (!id) return layer.msg('请选择检测标准')
                if (count !== 1) return layer.msg('只能选择一个检测标准进行编辑')


                this.edit_infoEdit = false
                setTimeout(() => {
                    this.edit_timeEdit = new Date() / 1;
                    this.edit_infoEdit = info
                    this.drawer_visibleEdit = true;
                })

            },
            // 定义 关闭抽屉时的
            onCloseDrawerEdit() {
                this.drawer_visibleEdit = false;
                this.Get_check_bz();
            },
            // 定义 打开抽屉时的 新增检测标准
            showDrawer(item) {
                this.edit_time = new Date() / 1;
                this.edit_info = item
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的
            onCloseDrawer() {
                this.Get_check_bz();
                this.drawer_visible = false;
            },

        }
    }
</script>

<style scoped>

    /*******************w按钮区开始w*********************
        ***********************************************/
    .btner{
        width: 100%;
        background: #f3f3f3;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #e9e8e8;
        height: 26px;
        line-height: 24px;
        padding: 0 12px
        }

    .btner:last-child{ border: none}

    .isthis, .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        height: 26px;
        line-height: 24px;
        }

    .btnerlist i{
        color: #999999;
        font-size: 16px;
        margin-right: 3px;
        }

    .isthis i, .btnerlist:hover i{ color: #ffffff !important}

    .isDel, .isDel:hover{ background: #ff4d4f;border-radius: 4px; color: #ffffff !important}

    .isDel i, .isDel:hover, .isDel:hover i{ color: #ffffff !important}

    /*******************w按钮区结束w*********************
    ***********************************************/
    .layui-table td .table-table tr td:nth-child(1){ border-left: none !important;}

    .layui-table td .table-table tr:last-child td{ border-bottom: none !important;}

    .layui-table td .table-table tr:nth-child(1) td{ border-top: none !important;}

    .ml5{margin-left: 5px}

    .ant-table-column-sorter{
        display: inline-flex;
        flex-direction: column;
        margin-top: 0px;
        margin-left: 5px;
        cursor: pointer;
        width: 10px;
        vertical-align: middle;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }

    .ml5{
        margin-left: 5px
        }

    .active{ background: #ebf6ff}

    table tr{ cursor: pointer}

</style>
